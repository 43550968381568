import { format } from "date-fns";

const dateRanges = [
  {
    name: "最近1小时",
    time: 1
  },
  {
    name: "最近6小时",
    time: 6
  },
  {
    name: "最近12小时",
    time: 12
  },
  {
    name: "最近24小时",
    time: 24
  },
  {
    name: "今天",
    time: format(new Date(), "HH")
  },
  {
    name: "昨天",
    time: 0
  },
  {
    name: "最近7天",
    time: 7 * 24
  },
  {
    name: "最近30天",
    time: 30 * 24
  },
  {
    name: "最近3个月",
    time: 90 * 24
  },
  {
    name: "最近6个月",
    time: 180 * 24
  }
];

function time_val(h) {
  const d = new Date();
  d.setMinutes(0);
  d.setSeconds(0);

  let hours = d.getHours();
  const timestring = d.getTime();
  let start_time, end_time;
  if (h !== 0) {
    start_time = new Date(timestring - h * 3600 * 1000);
    end_time = new Date(timestring - 3600 * 1000);
  } else {
    start_time = new Date(timestring - 24 * 3600 * 1000 - hours * 3600 * 1000);
    end_time = new Date(timestring - hours * 3600 * 1000);
  }

  return {
    start_time: format(start_time, "yyyy-MM-dd HH:mm:ss"),
    end_time: format(end_time, "yyyy-MM-dd HH:mm:ss")
  };
}

export { dateRanges, time_val };
