// copied from: https://dev.to/stephannv/how-to-create-a-global-snackbar-using-nuxt-vuetify-and-vuex-1bda
const snackbarPlugin = {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error("Please provide vuex store.");
    }

    const _this = (Vue.prototype.$snackbar = {
      showMessage: function({ content = "", isError = false }) {
        store.commit(
          "snackbar/showMessage",
          { content, isError: !!isError },
          { root: true }
        );
      },
      delegate: function(promise) {
        return promise
          .then(successText => {
            _this.showMessage({
              content: successText || "Operation completed."
            });
          })
          .catch(reason => {
            _this.showMessage({
              content: reason || "Operation Failed.",
              isError: true
            });
          });
      },
      delegateError: function(promise) {
        return promise.catch(reason => {
          _this.showMessage({
            content: reason || "Operation Failed.",
            isError: true
          });
        });
      }
    });
  }
};
export default snackbarPlugin;
