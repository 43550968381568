<template>
  <div class="org-wrapper">
    <Breadcrumbs />
    <v-row class="content-wrapper" style="margin-top: 0px!important;">
      <v-alert
        outlined
        dense
        text
        type="info"
        icon="mdi-lightbulb-on-outline"
        class="text-left black--text rounded-sm body-2 pa-4 mt-4 alert-display-top"
      >
        <span>组织机构</span><br />
        用户可以创建多个组织机构。此模块用于管理IAM
        组织机构，可以进行组织机构的增删改查等操作<br />
      </v-alert>
      <v-row
        class="directory-tree fill-height main-content display-bottom"
        no-gutters
      >
        <v-navigation-drawer style="min-width:300px;" width="400" permanent>
          <v-card class="mx-auto" outlined>
            <v-card-title>组织机构</v-card-title>
            <v-card-text>
              <v-treeview
                :load-children="fetchChildren"
                :active.sync="activeItems"
                :open.sync="openedItems"
                :items="items"
                item-key="id"
                activatable
                selected-color="indigo"
                return-object
                expand-icon="mdi-chevron-down"
                on-icon="mdi-bookmark"
                off-icon="mdi-bookmark-outline"
                indeterminate-icon="mdi-bookmark-minus"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.root">
                    mdi-home
                  </v-icon>
                  <v-icon v-else>
                    mdi-domain
                  </v-icon>
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-navigation-drawer>
        <v-col class="department-content">
          <v-card outlined>
            <v-card-subtitle style="border-bottom: 1px solid #eee"
              >详情</v-card-subtitle
            >
            <v-card-text>
              <v-tabs v-model="tab">
                <v-tab key="account">用户</v-tab>
                <v-tab key="group">用户组</v-tab>
                <v-tab key="department">组织机构</v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tab"
                style="height:660px; position:relative;"
              >
                <v-tab-item
                  :transition="false"
                  :reverse-transition="false"
                  key="account"
                >
                  <v-app-bar
                    elevation="0"
                    dense
                    flat
                    class="directory-toolbar"
                    style="background: none;"
                    height="56"
                  >
                    <v-btn
                      depressed
                      color="primary"
                      @click.stop="show_sheet(0, 'create')"
                    >
                      {{ $t("account.Create Account") }}
                    </v-btn>
                    <v-select
                      :items="ACCOUNT_FILTERS"
                      v-model="accountFilterBy"
                      hide-details
                      dense
                      class="ml-2"
                      style="width: 120px; flex: none; background: none"
                    ></v-select>
                    <v-text-field
                      dense
                      hide-details
                      :placeholder="`请输入${accountFilterBy}进行搜索`"
                      class="ml-2 pr-3"
                      style="width: 250px; flex: none"
                      v-model="filterValue['account']"
                      @keydown.enter="searchAccounts()"
                      autocomplete="filterValue"
                      :readonly="drawer_status[0]"
                    >
                      <v-icon slot="append" color="gray">
                        mdi-magnify
                      </v-icon>
                    </v-text-field>
                    <v-btn
                      depressed
                      color="primary"
                      @click.stop="searchAccounts()"
                    >
                      {{ $t("account.Search") }}
                    </v-btn>
                  </v-app-bar>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left pl-4">
                            ID
                          </th>
                          <th class="text-left">
                            {{ $t("account.login_name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("account.fullname") }}
                          </th>
                          <th class="text-left">
                            {{ $t("account.mobilephone") }}
                          </th>
                          <th class="text-left">
                            {{ $t("account.emailaddress") }}
                          </th>
                          <th class="text-left">
                            {{ $t("account.external_id") }}
                          </th>
                          <th width="220" class="text-center">
                            {{ $t("Actions") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="accounts.length > 0">
                        <tr
                          v-for="item in accounts"
                          :key="item.id"
                          class="text-left"
                        >
                          <td class="pl-4">{{ item.id }}</td>
                          <td>{{ item.login_name }}</td>
                          <td>{{ item.fullname }}</td>
                          <td>{{ item.mobilephone || "-" }}</td>
                          <td>{{ item.emailaddress || "-" }}</td>
                          <td>{{ item.external_id }}</td>
                          <td class="text-center">
                            <v-btn
                              icon
                              elevation="0"
                              @click.stop="show_sheet(item.id, 'edit')"
                            >
                              <v-icon>
                                mdi-square-edit-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              title="修改用户组"
                              @click.stop="showAccountGroupsDrawer(item)"
                            >
                              <v-icon>
                                mdi-account-multiple-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              title="重置密码"
                              @click.stop="showChangeAccountPassword(item)"
                            >
                              <v-icon>
                                mdi-lock-reset
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              @click.stop="
                                show_transfer_drawer(
                                  'account',
                                  item.nickname || item.login_name,
                                  item.id
                                )
                              "
                              title="转岗"
                            >
                              <v-icon>
                                mdi-cog-transfer-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              color="red"
                              @click.stop="archiveAccount(item)"
                            >
                              <v-icon>
                                mdi-trash-can-outline
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="text-center">
                          <td
                            colspan="7"
                            style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                          >
                            暂无数据
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-pagination
                    v-if="Math.ceil(accountTotal / limit) > 1"
                    class="pagination"
                    style="position:absolute;bottom:0px;right:50px"
                    v-model="accountCurPage"
                    :length="Math.ceil(accountTotal / limit)"
                    total-visible="7"
                    @input="
                      accountPageChange(
                        activeItems[0].id,
                        accountCurPage,
                        limit
                      )
                    "
                  ></v-pagination>
                </v-tab-item>
                <v-tab-item
                  :transition="false"
                  :reverse-transition="false"
                  key="group"
                >
                  <v-app-bar
                    elevation="0"
                    dense
                    flat
                    class="directory-toolbar"
                    style="background: none;"
                    height="56"
                  >
                    <v-btn
                      depressed
                      color="primary"
                      @click.stop="show_sheet(0, 'create')"
                    >
                      {{ $t("group.Create Group") }}
                    </v-btn>
                    <v-text-field
                      dense
                      hide-details
                      :placeholder="`请输入组名进行搜索`"
                      class="ml-2 pr-3"
                      style="width: 250px; flex: none"
                      v-model="filterValue['group']"
                      @keydown.enter="searchGroups()"
                      autocomplete="filterValue"
                      :readonly="drawer_status[0]"
                    >
                      <v-icon slot="append" color="gray">
                        mdi-magnify
                      </v-icon>
                    </v-text-field>
                    <v-btn
                      depressed
                      color="primary"
                      @click.stop="searchGroups()"
                    >
                      {{ $t("group.Search") }}
                    </v-btn>
                  </v-app-bar>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left pl-4">
                            ID
                          </th>
                          <th class="text-left">
                            {{ $t("group.name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("group.external_id") }}
                          </th>
                          <th width="220" class="text-center">
                            {{ $t("Actions") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="groups.length > 0">
                        <tr
                          v-for="item in groups"
                          :key="item.id"
                          class="text-left"
                        >
                          <td class="pl-4">{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.external_id }}</td>
                          <td class="text-center">
                            <v-btn
                              icon
                              elevation="0"
                              @click.stop="show_sheet(item.id, 'edit')"
                            >
                              <v-icon>
                                mdi-square-edit-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              @click.stop="showGroupAccountsDrawer(item)"
                            >
                              <v-icon>
                                mdi-account-group-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              title="移动用户组"
                              @click.stop="
                                show_transfer_drawer(
                                  'group',
                                  item.name,
                                  item.id
                                )
                              "
                            >
                              <v-icon>
                                mdi-cog-transfer-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              color="red"
                              @click.stop="archiveGroup(item)"
                            >
                              <v-icon>
                                mdi-trash-can-outline
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="text-center">
                          <td
                            colspan="4"
                            style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                          >
                            暂无数据
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-pagination
                    v-if="Math.ceil(groupTotal / limit) > 1"
                    class="pagination"
                    style="position:absolute;bottom:0px;right:50px"
                    v-model="groupCurPage"
                    :length="Math.ceil(groupTotal / limit)"
                    total-visible="7"
                    @input="
                      groupPageChange(activeItems[0].id, groupCurPage, limit)
                    "
                  ></v-pagination>
                </v-tab-item>
                <v-tab-item
                  :transition="false"
                  :reverse-transition="false"
                  key="department"
                >
                  <v-app-bar
                    elevation="0"
                    dense
                    flat
                    class="directory-toolbar"
                    style="background: none;"
                    height="56"
                  >
                    <v-btn
                      depressed
                      color="primary"
                      @click.stop="show_sheet(0, 'create')"
                    >
                      {{ $t("department.Create Department") }}
                    </v-btn>
                    <v-text-field
                      dense
                      hide-details
                      :placeholder="`请输入名称进行搜索`"
                      class="ml-2 pr-3"
                      style="width: 250px; flex: none"
                      v-model="filterValue['department']"
                      @keydown.enter="searchDepartments()"
                      autocomplete="filterValue"
                      :readonly="drawer_status[0]"
                    >
                      <v-icon slot="append" color="gray">
                        mdi-magnify
                      </v-icon>
                    </v-text-field>
                    <v-btn
                      depressed
                      color="primary"
                      @click.stop="searchDepartments()"
                    >
                      {{ $t("department.Search") }}
                    </v-btn>
                  </v-app-bar>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left pl-4">
                            ID
                          </th>
                          <th class="text-left">
                            {{ $t("department.name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("department.external_id") }}
                          </th>
                          <th width="220" class="text-center">
                            {{ $t("Actions") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="departments.length > 0">
                        <tr
                          v-for="item in departments"
                          :key="item.id"
                          class="text-left"
                        >
                          <td class="pl-4">{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.external_id }}</td>
                          <td class="text-center">
                            <v-btn
                              icon
                              elevation="0"
                              @click.stop="show_sheet(item.id, 'edit')"
                            >
                              <v-icon>
                                mdi-square-edit-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              title="移动部门"
                              :disabled="item.parent_id == null ? true : false"
                              @click.stop="
                                show_transfer_drawer(
                                  'department',
                                  item.name,
                                  item.id
                                )
                              "
                            >
                              <v-icon>
                                mdi-cog-transfer-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              elevation="0"
                              color="red"
                              :disabled="item.parent_id == null ? true : false"
                              @click.stop="archiveDepartment(item)"
                            >
                              <v-icon>
                                mdi-trash-can-outline
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="text-center">
                          <td
                            colspan="4"
                            style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                          >
                            暂无数据
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-pagination
                    v-if="Math.ceil(departmentTotal / limit) > 1"
                    class="pagination"
                    style="position:absolute;bottom:0px;right:50px"
                    v-model="departmentCurPage"
                    :length="Math.ceil(departmentTotal / limit)"
                    total-visible="7"
                    @input="
                      departmentPageChange(
                        activeItems[0].id,
                        departmentCurPage,
                        limit
                      )
                    "
                  ></v-pagination>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <Component
      :is="current_editor"
      :entity_info="current_entity"
      :is_modify="current_edit_type === 'edit'"
      :show="drawer_status"
    />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import AccountEditor from "@/views/directory/drawer/AccountEditor";
import ConfirmBox from "@/components/ConfirmBox";
import TransferDrawer from "@/views/directory/drawer/TransferDrawer";
import ChangePassword from "@/views/directory/drawer/ChangePassword";
import GroupEditor from "@/views/directory/drawer/GroupEditor";
import DepartmentEditor from "@/views/directory/drawer/DepartmentEditor";
import AccountGroupsDrawer from "@/views/directory/drawer/AccountGroupsDrawer";
import GroupsAccountDrawer from "@/views/directory/drawer/GroupsAccountDrawer";
import Breadcrumbs from "@/components/Breadcrumbs";

const TAB_KEYS = ["account", "group", "department"];
const ACCOUNT_FILTERS = ["账户名称", "姓名", "手机号码", "邮箱"];
const ACCOUNT_FILTER_KEYS = [
  "login_name",
  "fullname",
  "mobilephone",
  "emailaddress"
];
const EDITORS = {
  account: AccountEditor,
  group: GroupEditor,
  department: DepartmentEditor
};
export default {
  name: "DepartmentTree",
  components: { ConfirmBox, Breadcrumbs },
  data: () => ({
    // static UI
    tab: null,
    accountCurPage: 1,
    groupCurPage: 1,
    departmentCurPage: 1,
    limit: 10,
    accountTotal: 0,
    groupTotal: 0,
    departmentTotal: 0,
    ACCOUNT_FILTERS,
    filterValue: {
      account: "",
      group: "",
      department: ""
    },

    // interactive UI
    accountFilterBy: ACCOUNT_FILTERS[0],
    activeItems: [{ id: 0 }],
    openedItems: [],
    // forbid deactivate behavior
    activeItem: null,
    items: [
      {
        id: 0,
        name: "Loading...",
        children: []
      }
    ],
    accounts: [],
    groups: [],
    departments: [],
    // drawer
    current_editor: null,
    current_entity: null,
    current_edit_type: "create",
    drawer_status: [false, false]
  }),
  async created() {
    let root = await this.loadRootDepartment();
    await this.fetchChildren(root);
    this.openedItems = [root];
  },
  methods: {
    accountPageChange(activeItemId, accountCurPage, limit) {
      if (this.filterValue["account"].trim() === "") {
        this.fetchAccounts(activeItemId, accountCurPage, limit);
      } else {
        this.searchAccounts(accountCurPage, limit);
      }
    },
    groupPageChange(activeItemId, groupCurPage, limit) {
      if (this.filterValue["group"].trim() === "") {
        this.fetchGroups(activeItemId, groupCurPage, limit);
      } else {
        this.searchGroups(groupCurPage, limit);
      }
    },
    departmentPageChange(activeItemId, departmentCurPage, limit) {
      if (this.filterValue["department"].trim() === "") {
        this.fetchDescendants(activeItemId, departmentCurPage, limit);
      } else {
        this.searchDepartments(departmentCurPage, limit);
      }
    },
    show_sheet(item_id, edit_type) {
      this.current_editor = EDITORS[TAB_KEYS[this.tab]];
      this.current_edit_type = edit_type;
      this.current_entity = { id: item_id, parent: this.activeItems[0] };
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    show_transfer_drawer(entity_type, entity_name, entity_id) {
      this.current_editor = TransferDrawer;
      this.current_edit_type = "transfer";
      this.current_entity = {
        name: entity_name,
        type: entity_type,
        id: entity_id
      };
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    showAccountGroupsDrawer(item) {
      this.current_editor = AccountGroupsDrawer;
      this.current_edit_type = "groups";
      this.current_entity = item;
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    showGroupAccountsDrawer(item) {
      this.current_editor = GroupsAccountDrawer;
      this.current_edit_type = "accounts";
      this.current_entity = item;
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    showChangeAccountPassword(account) {
      this.current_editor = ChangePassword;
      this.current_edit_type = account.login_name ? "edit" : "create";
      this.current_entity = {
        fullname: account.fullname,
        login_name: account.login_name || account.preferred_username,
        id: account.id
      };
      this.$set(this.drawer_status, 0, true);
    },
    fetchForType(departmentId, type) {
      if (departmentId === 0) {
        // initializing
        return;
      }

      switch (type) {
        case "account": {
          return this.fetchAccounts(departmentId);
        }
        case "group": {
          return this.fetchGroups(departmentId);
        }
        case "department": {
          return this.fetchDescendants(departmentId);
        }
      }
    },
    fetchDescendants(departmentId, departmentCurPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/department/${departmentId}/descendants?offset=${(departmentCurPage -
            1) *
            limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.departmentTotal = res.total;
          if (departmentId === 1) {
            this.departments = res.departments.filter(item => item.parent_id);
          } else {
            this.departments = res.departments;
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchChildren(item) {
      return this.$http
        .get(`api/department/${item.id}/children`)
        .delegateTo(api_request)
        .then(children => {
          item.children.push(...children);
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchAccounts(departmentId, accountCurPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/department/${departmentId}/accounts?offset=${(accountCurPage -
            1) *
            limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.accountTotal = res.total;
          this.accounts = res.accounts;
        })
        .catch(({ code, message }) => {
          throw `数据获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchGroups(departmentId, groupCurPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/department/${departmentId}/groups?offset=${(groupCurPage - 1) *
            limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.groupTotal = res.total;
          this.groups = res.groups;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    searchAccounts(accountCurPage = 1, limit = 10) {
      let idx = ACCOUNT_FILTERS.findIndex(
        data => data === this.accountFilterBy
      );
      return this.$http
        .get(
          `api/department/${this.activeItems[0].id}/accounts/search?filter_by=${
            ACCOUNT_FILTER_KEYS[idx]
          }&value=${this.filterValue["account"]}&offset=${(accountCurPage - 1) *
            limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.accountTotal = res.total;
          this.accounts = res.account_infos;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    searchGroups(groupCurPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/department/${this.activeItems[0].id}/groups/search?value=${
            this.filterValue["group"]
          }&offset=${(groupCurPage - 1) * limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.groupTotal = res.total;
          this.groups = res.groups;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    searchDepartments(departmentCurPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/department/${this.activeItems[0].id}/departments/search?value=${
            this.filterValue["department"]
          }&offset=${(departmentCurPage - 1) * limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.departmentTotal = res.total;
          this.departments = res.departments;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    loadRootDepartment() {
      return this.$http
        .get(`api/department/root`)
        .delegateTo(api_request)
        .then(root => {
          let rootItem = {
            id: root.id,
            root: true,
            name: root.name === "." ? "企业根目录" : root.name,
            children: []
          };
          this.$set(this.items, 0, rootItem);
          this.$set(this.activeItems, 0, rootItem);

          return rootItem;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    refreshList() {
      return this.fetchForType(this.activeItems[0].id, TAB_KEYS[this.tab]);
    },
    refreshTree() {
      let root = this.items[0];

      if (Array.isArray(root.children) && !root.children.length) {
        return;
      }
      return this.$http
        .get(`api/department/${root.id}/descendants`)
        .delegateTo(api_request)
        .then(descendants => {
          let openedIds = this.openedItems.map(item => item.id);
          let nodeMap = Object.fromEntries(
            descendants.departments.map(node => [node.id, node])
          );

          root = nodeMap[root.id] = JSON.parse(JSON.stringify(root));
          root.children = [];
          for (let node of descendants.departments) {
            let parent = nodeMap[node.parent_id];
            if (parent === undefined) {
              // alert(`parent should included`);
              continue;
            }

            if (!openedIds.includes(node.parent_id)) {
              parent.children = [];
            } else {
              if (parent.children === undefined) {
                parent.children = [node];
              } else {
                parent.children.push(node);
              }
            }
          }

          this.items = [root];
          this.activeItems[0] = this.activeItem = nodeMap[this.activeItem.id];
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    archiveAccount(account) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除账户？ #${
            account.id
          } ${account.login_name || account.nickname}`,
          callback: () => {
            return this.$http
              .post(`api/account/${account.id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.accountCurPage =
                  this.accountCurPage > Math.ceil((this.accountTotal - 1) / 10)
                    ? this.accountCurPage - 1
                    : this.accountCurPage;
                if (this.accountTotal - 1 > 0) {
                  this.accountPageChange(
                    this.activeItems[0].id,
                    this.accountCurPage,
                    10
                  );
                } else {
                  this.refreshList();
                }
                return "账户已成功删除";
              })
              .catch(({ code, message }) => {
                throw `账户删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    archiveGroup(group) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除用户组 ${group.name}(#${group.id})？`,
          callback: () => {
            return this.$http
              .post(`api/group/${group.id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.groupCurPage =
                  this.groupCurPage > Math.ceil((this.groupTotal - 1) / 10)
                    ? this.groupCurPage - 1
                    : this.groupCurPage;
                if (this.groupTotal - 1 > 0) {
                  this.groupPageChange(
                    this.activeItems[0].id,
                    this.groupCurPage,
                    10
                  );
                } else {
                  this.refreshList();
                }
                return "用户组已成功删除";
              })
              .catch(({ code, message }) => {
                throw `用户组删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    archiveDepartment(department) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除部门 ${department.name}(#${department.id})？`,
          callback: () => {
            return this.$http
              .post(`api/department/${department.id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.refreshTree();
                this.departmentCurPage =
                  this.departmentCurPage >
                  Math.ceil((this.departmentTotal - 1) / 10)
                    ? this.departmentCurPage - 1
                    : this.departmentCurPage;
                if (this.departmentTotal - 1 > 0) {
                  this.departmentPageChange(
                    this.activeItems[0].id,
                    this.departmentCurPage,
                    10
                  );
                } else {
                  this.refreshList();
                }
                return "部门已成功删除";
              })
              .catch(({ code, message }) => {
                throw `部门删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    }
  },
  watch: {
    activeItems(val) {
      if (this.activeItem === val[0]) {
        // ignore same item
        return;
      }

      if (val.length) {
        this.fetchForType(val[0].id, TAB_KEYS[this.tab]);
        this.activeItem = val[0];
      } else {
        // forbid deactivate behavior
        this.$set(this.activeItems, 0, this.activeItem);
      }
    },
    tab(val) {
      if (this.activeItems.length) {
        this.accountCurPage = 1;
        this.groupCurPage = 1;
        this.departmentCurPage = 1;
        this.accounts = [];
        this.departments = [];
        this.groups = [];

        this.fetchForType(this.activeItems[0].id, TAB_KEYS[val]);
      }
    },
    drawer_status(val) {
      if (val[0] === val[1]) {
        // ignore same value
        return;
      }

      val[1] = val[0];

      // this.refreshList();
      if (this.tab === 0) {
        if (this.accountCurPage > 1) {
          this.accountPageChange(
            this.activeItems[0].id,
            this.accountCurPage,
            10
          );
        } else {
          this.refreshList();
        }
      } else if (this.tab === 1) {
        if (this.groupCurPage > 1) {
          this.groupPageChange(this.activeItems[0].id, this.groupCurPage, 10);
        } else {
          this.refreshList();
        }
      } else {
        if (this.departmentCurPage > 1) {
          this.departmentPageChange(
            this.activeItems[0].id,
            this.departmentCurPage,
            10
          );
        } else {
          this.refreshList();
        }
      }

      if (!val[0]) {
        if (TAB_KEYS[this.tab] === "department") {
          this.refreshTree();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.org-wrapper {
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  .alert-display-top {
    width: 100%;
  }
  .display-bottom {
    width: 100%;
    flex-wrap: nowrap;
  }
  .v-alert {
    /deep/ .v-alert__content {
      color: #213142;
    }
    /deep/ .v-alert__icon {
      font-size: 2rem !important;
      height: 3rem;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>

<style lang="sass">
.directory-tree
  .v-treeview-node__root,
  .v-treeview-node__root > .v-treeview-node__content > *
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

  .col.department-content
    padding-left: 12px

  .v-app-bar.directory-toolbar
    .v-toolbar__content
      padding: 0

  .v-text-field input
    line-height: 26px

  .v-text-field .v-input__append-inner,
  .v-text-field .v-input__icon--append
    height: 32px

  .v-select__selection
    margin-left: 4px !important
    font-size: 14px

  .v-text-field__slot
    font-size: 14px
    padding-left: 4px

  .v-btn:not(.v-btn--round).v-size--default
    height: 32px
    padding-left: 12px
    padding-right: 12px

  .v-subheader
    padding-top: 4px !important

.v-menu__content .v-list--dense
  padding: 4px !important
</style>
