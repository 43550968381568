import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/dashboard/Index.vue";
import Login0 from "../views/Login0.vue";
import store from "../store";
import PublicLayout from "../layouts/Public";
import DashboardLayout from "../layouts/Dashboard";
import SourceList from "@/views/source/List";
import SocialList from "@/views/social/List";
import CreateSource from "@/views/source/Create";
import DepartmentTree from "@/views/directory/DepartmentTree";
import { isMobile } from "@/util/util";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard"
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/",
        component: Home,
        meta: {
          title: "用户管理后台 - 统计概况"
        }
      }
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/login/Index"),
    meta: {
      public: true,
      title: "用户登录",
      isLogin: true
    }
  },
  {
    path: "/logins/:external_id.html",
    component: () => import("@/views/login/CustomIndex"),
    meta: {
      public: true,
      title: "用户登录",
      isLogin: true
    }
  },
  {
    path: "/errorprompt",
    component: () => import("@/views/wechat/component/ErrorMobilePrompt"),
    meta: {
      public: true,
      title: "温馨提示",
      isLogin: true
    }
  },
  {
    path: "/user/home",
    component: () => import("@/views/user/Home"),
    meta: {
      title: "用户中心",
      permission: true
    }
  },
  {
    name: "launchpad",
    path: "/user/launchpad",
    component: () => import("@/views/user/Application"),
    meta: {
      title: "应用列表",
      permission: true
    }
  },
  {
    path: `/wechat/authorize`,
    component: () => import("@/views/wechat/Authorize"),
    meta: {
      public: true,
      title: "用户登录",
      permission: true
    }
  },
  {
    path: "/wechat/callback",
    component: () => import("@/views/wechat/CallBack"),
    meta: {
      public: true,
      title: "用户登录",
      permission: true
    }
  },
  {
    path: `/wxwork/authorize`,
    component: () => import("@/views/wxwork/Authorize"),
    meta: {
      public: true,
      title: "用户登录",
      permission: true
    }
  },
  {
    path: "/wxwork/callback",
    component: () => import("@/views/wxwork/CallBack"),
    meta: {
      public: true,
      title: "用户登录",
      permission: true
    }
  },
  {
    path: "/login0",
    component: PublicLayout,
    children: [
      {
        path: "",
        component: Login0,
        meta: {
          public: true,
          title: "用户登录",
          isLogin: true
        }
      }
    ]
  },
  {
    path: "/about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PublicLayout,
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
        meta: {
          public: true
        }
      }
    ]
  },
  {
    path: "/source/list",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: SourceList,
        meta: {
          breadcrumbs: [
            {
              text: "Identity Source"
            }
          ],
          title: "用户管理后台 - 身份源管理"
        }
      }
    ]
  },
  {
    path: "/source/create",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: CreateSource,
        meta: {
          breadcrumbs: [
            {
              text: "Authentication Source"
            },
            {
              text: "Create Identity Source"
            }
          ],
          title: "用户管理后台 - 添加身份源"
        }
      }
    ]
  },
  {
    path: "/source/view/:id",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/source/LinkOrigin"),
        meta: {
          breadcrumbs: [
            {
              text: "Identity Source"
            }
          ],
          title: "用户管理后台 - 修改身份源"
        }
      }
    ]
  },
  {
    path: "/customLogin/list",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/customLogin/List"),
        meta: {
          breadcrumbs: [
            {
              text: "Custom Login"
            }
          ],
          title: "用户管理后台 - 自定义登录"
        }
      }
    ]
  },
  {
    path: "/customLogin/view/:id",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/customLogin/View"),
        meta: {
          breadcrumbs: [
            {
              text: "Custom Login"
            },
            {
              text: "Custom Details"
            }
          ],
          title: "用户管理后台 - 修改自定义登录"
        }
      }
    ]
  },
  {
    path: "/social/list",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: SocialList,
        meta: {
          breadcrumbs: [
            {
              text: "Social Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/mfa/list",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/mfa/List"),
        meta: {
          breadcrumbs: [
            {
              text: "Multi-Factor Login"
            }
          ],
          title: "用户管理后台 - MFA"
        }
      }
    ]
  },
  {
    path: "/directory/tree",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: DepartmentTree,
        meta: {
          breadcrumbs: [
            {
              text: "Organization"
            }
          ],
          title: "用户管理后台 - 组织机构"
        }
      }
    ]
  },
  {
    path: "/accounts",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/account/List"),
        meta: {
          breadcrumbs: [
            {
              text: "User Manage"
            }
          ],
          title: "用户管理后台 - 用户管理"
        }
      }
    ]
  },
  {
    path: "/account/view/:id",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/account/View"),
        meta: {
          breadcrumbs: [
            {
              text: "Accounts"
            },
            {
              text: "Account Details"
            }
          ],
          title: "用户管理后台 - 修改用户"
        }
      }
    ]
  },
  {
    path: "/groups",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/group/List"),
        meta: {
          breadcrumbs: [
            {
              text: "Group Manage"
            }
          ],
          title: "用户管理后台 - 用户管理"
        }
      }
    ]
  },
  {
    path: "/group/view/:id",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/group/View"),
        meta: {
          breadcrumbs: [
            {
              text: "Groups"
            },
            {
              text: "Group Details"
            }
          ],
          title: "用户管理后台 - 修改用户"
        }
      }
    ]
  },
  {
    path: "/attributes",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/directory/AttributeManage"),
        meta: {
          breadcrumbs: [
            {
              text: "Attribute Manage"
            }
          ],
          title: "用户管理后台 - 属性管理"
        }
      }
    ]
  },
  {
    path: "/applications/list",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/application/List"),
        meta: {
          breadcrumbs: [
            {
              text: "Application List"
            }
          ],
          title: "用户管理后台 - 应用列表"
        }
      }
    ]
  },
  {
    path: "/applications/create",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/application/CreateApplication"),
        meta: {
          breadcrumbs: [
            {
              text: "Application List"
            },
            {
              text: "Create Application"
            }
          ],
          title: "用户管理后台 - 创建应用"
        }
      }
    ]
  },
  {
    path: "/applications/sub_account/:id",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/application/SubList"),
        meta: {
          breadcrumbs: [
            {
              text: "Application List"
            },
            {
              text: "SubApplication List"
            }
          ],
          title: "应用管理后台 - 子账户列表"
        }
      }
    ]
  },
  {
    path: "/applications/view/:id",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/application/LinkOrigin"),
        meta: {
          breadcrumbs: [
            {
              text: "Site Management"
            }
          ],
          title: "用户管理后台 - 站点管理"
        }
      }
    ]
  },
  {
    path: "/audit/login",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/audit/AuditLogLogin"),
        meta: {
          breadcrumbs: [
            {
              text: "Login Log"
            }
          ],
          title: "用户管理后台 - 登录日志"
        }
      }
    ]
  },
  {
    path: "/audit/applicationlogin",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/audit/ApplicationLogin"),
        meta: {
          breadcrumbs: [
            {
              text: "Application Log"
            }
          ],
          title: "用户管理后台 - 应用访问日志"
        }
      }
    ]
  },
  {
    path: "/setting/personalization",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/setting/Personalization"),
        meta: {
          breadcrumbs: [
            {
              text: "Personalization Settings"
            }
          ],
          title: "用户管理后台 - 个性化设置"
        }
      }
    ]
  },
  {
    path: "/setting/license",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/setting/License"),
        meta: {
          breadcrumbs: [
            {
              text: "Authorization Information"
            }
          ],
          title: "用户管理后台 - 授权管理"
        }
      }
    ]
  },
  {
    path: "/setting/security",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/setting/Security"),
        meta: {
          breadcrumbs: [
            {
              text: "Security Settings"
            }
          ],
          title: "用户管理后台 - 安全设置"
        }
      }
    ]
  },
  {
    path: "/method/list",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/method/List"),
        meta: {
          breadcrumbs: [
            {
              text: "Authentication Method"
            }
          ],
          title: "用户管理后台 - 认证方式管理"
        }
      }
    ]
  },
  {
    path: "/method/create",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: () => import("../views/method/Create"),
        meta: {
          breadcrumbs: [
            {
              text: "Create Authentication Method"
            }
          ],
          title: "用户管理后台 - 添加认证方式"
        }
      }
    ]
  }
];
const DEFAULT_BREADCRUMB = {
  text: "Dashboard",
  disabled: true,
  href: ""
};

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  } else {
    return originalPush.call(this, location).catch(err => err);
  }
};

router.beforeEach(async (to, from, next) => {
  if (!store.state.initialized) {
    // the store initialize will set initialized to true
    await store.dispatch("initialize");
    await store.dispatch("auth_adminEnter");
    isMobile()
      ? await store.dispatch("fetchMLoginTitle")
      : await store.dispatch("fetchLoginTitle");
  } else {
    if (from.path === "/login") {
      await store.dispatch("fetchDefaultLaunchpad");
    }
  }

  if (to.meta.title) {
    document.title = `${(isMobile()
      ? store.state.mLoginTitle
      : store.state.loginTitle) || to.meta.title}`;
  }

  // if user not logged in or the route is not public
  if (!store.state.identity && !to.meta.public) {
    next("/login");
  } else if (
    store.state.identity &&
    store.state.identity.claims.role !== "system" &&
    !to.meta.permission
  ) {
    if (store.state.defaultLaunchpadId) {
      next({
        name: "launchpad",
        params: {
          id: store.state.defaultLaunchpadId
        }
      });
    } else {
      next("/user/launchpad");
    }
  } else if (
    store.state.identity && // logged-in
    !to.meta.permission && // system page
    process.env.VUE_APP_ENV_NAME === "production" && // production mode
    location.port !== "3088" && // no valid port
    store.state.adminEntered
  ) {
    if (store.state.defaultLaunchpadId) {
      next({
        name: "launchpad",
        params: {
          id: store.state.defaultLaunchpadId
        }
      });
    } else {
      next("/user/launchpad");
    }
  } else {
    store.commit(
      "change_breadcrumbs",
      to.meta.breadcrumbs || [DEFAULT_BREADCRUMB]
    );
    next();
  }
});

export default router;
