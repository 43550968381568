<template>
  <v-app>
    <router-view v-if="isRouterAlive" />
  </v-app>
</template>

<script>
import "@/styles/overrides.sass";

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>
