// copied from: https://dev.to/stephannv/how-to-create-a-global-snackbar-using-nuxt-vuetify-and-vuex-1bda
export const namespaced = true;

export const state = () => ({
  content: "",
  isError: false
});

export const mutations = {
  showMessage(state, payload) {
    state.content = payload.content;
    state.isError = payload.isError;
  }
};
