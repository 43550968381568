// `window.crypto.subtle` is forbidden from insecure context.
const forge = require("node-forge");
forge.options.usePureJavaScript = true;
import x25519 from "./x25519";

function cryptoRandomBytes(length) {
  let array = new Uint8Array(length);
  let crypto = window.crypto || window.msCrypto;
  if (crypto) {
    return crypto.getRandomValues(array);
  }
}

function safe_b64(b64s) {
  return rstrip(b64s.replace(/\+/g, "-").replace(/\//g, "_"), "=");
}

function urlsafe_b64encode(bytes) {
  return safe_b64(forge.util.encode64(bytes));
}

function urlsafe_b64encode_array(array) {
  return safe_b64(forge.util.binary.base64.encode(array));
}

// ASCII-safe JSON.stringify
// @see: https://stackoverflow.com/questions/31649362/json-stringify-and-unicode-characters
// eslint-disable-next-line no-unused-vars
function safe_stringify(o) {
  let json = JSON.stringify(o);
  return json.replace(/[\u007F-\uFFFF]/g, function(chr) {
    return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
  });
}

function urlsafe_b64decode(s) {
  s = s.replace(/-/g, "+").replace(/_/g, "/");
  s += "=".repeat(3 & (~(s.length & 3) + 1));

  return forge.util.decode64(s);
}

function urlsafe_b64decode_array(s) {
  s = s.replace(/-/g, "+").replace(/_/g, "/");
  s += "=".repeat(3 & (~(s.length & 3) + 1));

  return forge.util.binary.base64.decode(s);
}

function rstrip(str, remove) {
  while (str.length > 0 && remove.indexOf(str.charAt(str.length - 1)) !== -1) {
    str = str.substr(0, str.length - 1);
  }
  return str;
}

function packNumber(n, width) {
  let bytes = "";
  while (n > 0) {
    bytes = String.fromCharCode(n & 255) + bytes;
    n >>= 8;
  }

  if (width > bytes.length) {
    bytes = "\x00".repeat(width - bytes.length) + bytes;
  }

  return bytes;
}

function composeOtherInfo(apu = "", apv = "") {
  return (
    "\x00\x00\x00\x07A256GCM" +
    packNumber(apu.length, 4) +
    apu +
    packNumber(apv.length, 4) +
    apv +
    "\x00\x00\x01\x00" // 256
  );
}

function concatKDF(sharedKey, otherInfo) {
  // simplified for 1 round
  let md = forge.md.sha256.create();
  md.update("\x00\x00\x00\x01" + sharedKey + otherInfo);
  return md.digest().bytes();
}

function startEphemeralSession(staticPublic) {
  let privateArray = cryptoRandomBytes(32);
  x25519.clamp(privateArray);
  let publicArray = x25519.getPublic(privateArray);

  let staticPublicArray = forge.util.binary.raw.decode(
    urlsafe_b64decode(staticPublic)
  );
  let sharedKeyArray = x25519.getSharedKey(privateArray, staticPublicArray);
  // we use jti as apu & apv, avoid constant variables
  let masterKey = concatKDF(
    forge.util.binary.raw.encode(sharedKeyArray),
    composeOtherInfo()
  );

  return {
    publicKey: urlsafe_b64encode_array(publicArray),
    masterKey
  };
}

function encryptPassword(passwordKey, challenge, password) {
  let session = startEphemeralSession(passwordKey);
  let encrypted = packedAesEncrypt(
    session.masterKey,
    `${challenge}.${password}`
  );
  return `${session.publicKey}.${encrypted}`;
}

function packedAesEncrypt(key, password) {
  let cipher = forge.cipher.createCipher("AES-GCM", key);
  let iv = cryptoRandomBytes(12);
  cipher.start({
    iv: forge.util.binary.raw.encode(iv), // should be a 12-byte binary-encoded string or byte buffer
    tagLength: 128 // optional, defaults to 128 bits
  });

  cipher.update(forge.util.createBuffer(password));
  cipher.finish();

  let encrypted = cipher.output.getBytes();
  let tag = cipher.mode.tag.getBytes();

  return `${urlsafe_b64encode_array(iv)}.${urlsafe_b64encode(
    encrypted
  )}.${urlsafe_b64encode(tag)}`;
}

export { encryptPassword, urlsafe_b64decode_array };
