<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("source.See {type} adapter config", {
                  type: "WEBVPN"
                })}：${name}`
              : this.type === "create"
              ? `${$t("source.Create {type} adapter config", {
                  type: "WEBVPN"
                })}`
              : `${$t("source.Edit {type} adapter config", {
                  type: "WEBVPN"
                })}：${name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" :model="form" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        WebVPN身份源列表项
      </v-alert>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader> 身份源ID</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            v-model="external_id"
            :disabled="type !== 'create'"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="$t('ID Info')"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 显示名称</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :auto-grow="true"
            rows="1"
            v-model="name"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="form">
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 认证地址</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :rules="is_readonly ? [] : rules.isurl"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="
              is_readonly ? '' : 'WebVPN的认证地址，如：https://xxx.edu.cn'
            "
            v-model="form.webvpn_auth_url"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="type === 'create' ? create(form) : save(form)"
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { modify_request, create_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "WebVPNEditor",
  props: ["source_id", "is_readonly", "type", "allDatas"],
  data() {
    let isTrueUrl = value => {
      let reg = /(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      name: "",
      external_id: "webvpn",
      finish_url: "",
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      }
    };
  },
  created() {
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          new_name: this.name,
          new_options: this.form
        };
        const promise = modify_request(this.$route.params.id, payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "更新成功";
          })
          .catch(({ code, message }) => {
            throw `更新失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          });
        this.$snackbar.delegate(promise);
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        let payload = {
          name: this.name,
          external_id: this.external_id,
          type: "WEBVPN",
          options: this.form //严格对应后端的options
        };
        create_request(payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            this.$router.push({ path: `/source/list` });
            return "创建成功";
          })
          .catch(({ code, message }) => {
            throw `创建失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    fetchData() {
      let { name, options, finish_url, external_id } = this.allDatas;
      this.name = name;
      if (options) {
        this.form = options;
      }
      this.external_id = external_id;
      this.finish_url = finish_url;
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          this.external_id = "webvpn";
        }
      }
    }
  },
  watch: {
    allDatas() {
      this.fetchData();
    }
  }
};
</script>
