import Vue from "vue";
import VueResource from "vue-resource";
import store from "./store";
import router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./styles/base.less";
import App from "./App.vue";
import snackbarPlugin from "@/plugins/snackbar";
import { delegateTo } from "@/util/network";

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueResource);
Vue.Promise.prototype.delegateTo = delegateTo;
Vue.http.options.credentials = true;
Vue.http.options.root = process.env.VUE_APP_BASE_URL + "/";
Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (store.state.token) {
    request.headers.set("Authorization", `${store.state.token}`);
  }
  // continue to next interceptor
  next();
});

Vue.use(snackbarPlugin, { store });

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
