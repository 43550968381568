function pieEchart(name, data, total) {
  let option = {
    title: {
      text: [`{value|${total > 0 ? total : "无数据"}}`],
      top: "39.5%",
      left: "23%",
      textAlign: "center",
      textStyle: {
        rich: {
          value: {
            fontSize: 30,
            fontWeight: "600",
            color: "#555"
          }
        }
      }
    },
    tooltip: {
      trigger: "item"
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      left: "50%",
      top: 26,
      icon: "rect",
      itemHeight: 26,
      itemWidth: 8,
      selectedMode: false,
      textStyle: {
        rich: {
          name: {
            fontSize: 12,
            color: "#011",
            padding: [6, 0, 0, 0]
          },
          num: {
            fontSize: 14,
            fontWeight: 600,
            padding: [4, 0, 0, 0],
            color: "#000"
          }
        }
      },
      formatter: function(name) {
        let tarValue;
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].name == name) {
              tarValue = data[i].value;
            }
          }
        }

        return [`{name|${name}}`, `{num|${tarValue || "无数据"}}`].join("\n");
      }
    },
    series: [
      {
        name,
        center: ["24%", "46%"],
        type: "pie",
        radius: ["50%", "74%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
          formatter: "{c}",
          fontSize: "30",
          fontWeight: "600",
          color: "#555"
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "30",
            fontWeight: "600"
          }
        },
        labelLine: {
          show: false
        },
        data
      }
    ]
  };

  return option;
}

export { pieEchart };
