<template>
  <div>
    <!-- <v-row
      class="pa-0 ma-0"
      v-bind:class="{ 'mb-15': breadcrumbs.length <= 1 }"
    > -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0">
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :disabled="true" class="font-weight-bold">{{
              $t(`breadcrumb.${item.text}`)
            }}</v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <div class="action-btn-group">
        <slot name="action-buttons" />
      </div>
    </v-row>
    <v-row class="page-title" v-if="breadcrumbs.length > 1">
      <v-btn icon @click="goback">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="title">{{
        $t(`breadcrumb.${breadcrumbs[breadcrumbs.length - 1].text}`)
      }}</span>
      <span class="title" v-if="curData">：{{ curData }}</span>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: ["curData", "goPage"],
  data() {
    return {
      sources: [
        {
          name: "CAS认证方式",
          type: "CAS",
          description: "使用CAS 2.0登录"
        },
        {
          name: "OAuth2认证方式",
          type: "OAUTH2",
          description: "使用OAuth 2.0登录"
        },
        {
          name: "企业微信认证方式",
          type: "WECHAT_ENTERPRISE",
          description: "使用企业微信微应用登录"
        },
        {
          name: "LDAP认证方式",
          type: "LDAP",
          description: "使用LDAP(如AD域)进行认证"
        }
      ]
    };
  },
  methods: {
    goback() {
      if (this.goPage) {
        this.$router.push(this.goPage);
      } else {
        this.$router.go(-1);
      }
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  }
};
</script>
