<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show[0]">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            this.is_modify
              ? $t("department.Edit Department")
              : $t("department.Create Department")
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form" style="height: 75vh">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        不知道怎么填？点这里
      </v-alert>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("department.label.parent_directory") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field
            :value="entity_info.parent.name"
            disabled
            readonly
            rows="1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("department.label.name") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="department['name']"
            :placeholder="$t('department.placeholder.name')"
            :hint="$t('department.hint.name')"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader> {{ $t("department.label.external_id") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="department['external_id']"
            :placeholder="$t('department.placeholder.external_id')"
            :hint="$t('department.hint.external_id')"
            persistent-hint
            :readonly="is_modify"
            :disabled="is_modify"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader>{{ $t("department.label.description") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            rows="1"
            v-model="department['description']"
            :placeholder="$t('department.placeholder.description')"
            :hint="$t('department.hint.description')"
            persistent-hint
            :auto-grow="true"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="3"></v-col>
        <v-col cols="9" class="text-left">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="is_modify ? modify() : create()"
            >{{ is_modify ? "提交修改" : "创建" }}</v-btn
          >
          <v-btn
            class="ml-6"
            elevation="0"
            medium
            color="gary"
            @click="resetForm()"
            >重置</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "DepartmentEditor",
  components: {},
  data() {
    let isTrueUrl = value => {
      let reg = /(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    return {
      department: {},
      defaults: {},

      valid: true,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      },
      // UI inter-communication
      show_datepicker: false
    };
  },
  props: ["entity_info", "is_modify", "show"],
  methods: {
    resetForm(clear) {
      if (clear) {
        this.defaults = {};
      }
      this.department = Object.assign({}, this.defaults);
    },
    modify() {
      // if (this.$refs.form.validate()) {
      let payload = {
        name: this.department["name"],
        description: this.department["description"]
      };
      this.$http
        .post(`api/department/${this.department.id}/modify`, payload)
        .delegateTo(api_request)
        .then(() => {
          this.$set(this.show, 0, false);
          this.resetForm(true);
          return "部门信息已成功修改";
        })
        .catch(({ code, message }) => {
          throw `部门信息修改失败：${this.$t(
            "api." + code
          )}, 额外信息: ${"api." + JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegate);
      // }
    },
    create() {
      this.department["parent_id"] = this.entity_info.parent.id;
      this.$http
        .post(`api/department/create`, this.department)
        .delegateTo(api_request)
        .then(() => {
          this.$set(this.show, 0, false);
          this.resetForm(true);
          return "创建成功";
        })
        .catch(({ code, message }) => {
          throw `部门创建失败：${this.$t("api." + code)}, 额外信息: ${"api." +
            JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegate);
    },
    fetchData(department_id) {
      this.$http
        .get(`api/department/${department_id}/describe`)
        .delegateTo(api_request)
        .then(department => {
          this.defaults = department;
          this.resetForm();
        })
        .catch(({ code, message }) => {
          throw `部门信息获取失败：${this.$t(
            "api." + code
          )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  watch: {
    watchShow(val) {
      if (this.is_modify && val) {
        this.fetchData(this.entity_info.id);
      } else {
        this.defaults = {};
      }
    }
  },
  computed: {
    watchShow() {
      return this.show[0];
    }
  }
};
</script>
