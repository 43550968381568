<template>
  <div>
    社会化登录页面
  </div>
</template>

<script>
export default {};
</script>

<style></style>
