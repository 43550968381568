<template>
  <v-navigation-drawer
    app
    temporary
    width="650px"
    right
    v-model="show[0]"
    class="directory-tree"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            `修改账号 ${entity_info.fullname || entity_info.login_name} 的密码`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-container class="pa-4" style="height: 75vh">
      <v-form class="pa-4" v-model="valid" ref="form">
        <v-row style="border-bottom: none !important;">
          <v-col cols="3" class="mt-2">
            <v-subheader
              ><span class="red--text">*</span>
              {{ $t("account.label.login_name") }}</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="entity_info['login_name']"
              :placeholder="$t('account.placeholder.login_name')"
              :readonly="is_modify"
              :disabled="is_modify"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          dense
          style="margin-top:0;"
          class="align-center"
          v-if="role"
        >
          <v-col cols="3">
            <v-subheader> 密码生成</v-subheader>
          </v-col>
          <v-col cols="9" class="d-flex">
            <v-checkbox
              v-model="autoPwd"
              label="自动生成并用短信下发密码"
              @change="createPwd(autoPwd)"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row no-gutters dense v-if="!autoPwd">
          <v-col cols="3" class="mt-2">
            <v-subheader
              ><span class="red--text">*</span>
              {{ $t("account.label.new_password") }}</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="account['new_password']"
              :placeholder="$t('account.placeholder.new_password')"
              :hint="$t('account.hint.password')"
              persistent-hint
              type="password"
              required
              :rules="rules.ispassword"
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense v-if="!autoPwd">
          <v-col cols="3" class="mt-2">
            <v-subheader
              ><span class="red--text">*</span>
              {{ $t("account.label.confirm_password") }}</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="account['confirm_password']"
              :placeholder="$t('account.placeholder.confirm_password')"
              :hint="$t('account.hint.confirm_password')"
              persistent-hint
              type="password"
              required
              :rules="rules.isconfirm"
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-row>
      <v-col class="d-flex" cols="3"></v-col>
      <v-col cols="9" class="text-left">
        <v-btn
          elevation="4"
          medium
          color="primary"
          @click.stop="changePassword()"
          >确认修改</v-btn
        >
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "ChangePassword",
  components: {},
  data() {
    let isTruePassword = value => {
      let reg = /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/;
      return reg.test(value);
    };
    let isConfirmPassword = value => {
      return this.account["new_password"] === value;
    };
    return {
      valid: true,
      account: {},
      autoPwd: false,
      role: null,
      rules: {
        ispassword: [
          v =>
            isTruePassword(v) ||
            "密码至少包含大小写字母+数字+特殊字符; 长度至少8位，最多20位。"
        ],
        isconfirm: [v => isConfirmPassword(v) || "新密码与确认密码不一致"]
      }
    };
  },
  props: ["show", "is_modify", "entity_info"],
  methods: {
    verifyRole() {
      let adminRole = this.$store.state.identity.claims.login_name;
      if (adminRole === this.entity_info.login_name) {
        this.role = true;
      } else {
        this.role = false;
      }
    },
    createPwd(autoPwd) {
      this.autoPwd = autoPwd;
    },
    changePassword() {
      if (this.$refs.form.validate()) {
        if (!this.is_modify) {
          this.account.preferred_username = this.entity_info.login_name;
        }

        if (this.autoPwd) {
          this.account["generate_password"] = true;
        }

        this.$http
          .post(
            `api/account/${this.entity_info.id}/change_password`,
            this.account
          )
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            return `已成功修改账户 ${this.entity_info.fullname || ""}(${this
              .entity_info.login_name || ""}) 的密码。`;
          })
          .catch(({ code, message }) => {
            throw `密码修改失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    }
  },
  watch: {
    watchShow(val) {
      if (val) {
        this.verifyRole();
      }
    }
  },
  computed: {
    watchShow() {
      return this.show[0];
    }
  }
};
</script>
