import store from "@/store";

export function delegateTo(delegate_func) {
  return delegate_func(this);
}

Promise.prototype.delegateTo = delegateTo;

export function api_request(promise) {
  return new Promise((resolve, reject) => {
    promise
      .then(response => {
        response
          .json()
          .then(json => {
            if (
              !json["status"] ||
              !json["code"] ||
              (json["data"] === undefined && json["message"] === undefined)
            ) {
              reject({ code: "InvalidJsonResponse", message: null });
            }

            if (json["status"] === "success") {
              resolve(json["data"]);
            } else {
              if (json["code"] === "InsufficientPermission") {
                store.dispatch("confirmPermission");
              }

              reject({
                code: json["code"],
                message: json["message"]
              });
            }
          })
          .catch(reason => {
            reject({ code: "UnableParseJsonResponse", message: reason });
          });
      })
      .catch(reason => {
        reject({ code: "UnableFetchResponse", message: reason });
      });
  });
}

export function delay(promise, milliseconds) {
  if (!(promise instanceof Promise)) {
    throw `${promise} is not Promise`;
  }
  return promise
    .then(result => {
      return new Promise(resolve =>
        setTimeout(() => resolve(result), milliseconds)
      );
    })
    .catch(reason => {
      return new Promise((_, reject) =>
        setTimeout(() => reject(reason), milliseconds)
      );
    });
}

import Vue from "vue";
export function modify_request(source_id, payload) {
  return Vue.http
    .post(`api/source/${source_id}/modify`, payload)
    .delegateTo(api_request);
}

export function create_request(payload) {
  return Vue.http.post(`api/source/create`, payload).delegateTo(api_request);
}

export function modify_application(source_id, payload) {
  return Vue.http
    .post(`api/application/${source_id}/modify`, payload)
    .delegateTo(api_request);
}

export function create_application(payload) {
  return Vue.http
    .post(`api/application/create`, payload)
    .delegateTo(api_request);
}

export function create_subApplication(applicationId, payload) {
  return Vue.http
    .post(`api/application/${applicationId}/sub_account/create`, payload)
    .delegateTo(api_request);
}

export function modify_subApplication(applicationId, curSubId, payload) {
  return Vue.http
    .post(
      `api/application/${applicationId}/sub_account/${curSubId}/modify`,
      payload
    )
    .delegateTo(api_request);
}
