<template>
  <v-app id="app">
    <v-app-bar
      app
      elevation="1"
      light
      clipped-right
      class="v-bar--underline"
      v-if="role"
    >
      <v-app-bar-nav-icon
        v-if="!drawer"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-img
        v-if="!drawer"
        alt="Vuetify Name"
        class="shrink mt-1"
        contain
        min-width="80"
        :src="require('@/assets/iam-logo2.png')"
        width="80"
      />
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" color="#444">
              {{ fullname || login_name }}
              <v-icon class="ml-1">mdi-account</v-icon>
            </v-btn>
          </template>
          <v-sheet class="overflow-hidden">
            <v-list dense expand nav>
              <v-list-item to="/user/home">
                <v-list-item-title class="d-flex align-center">
                  <v-icon class="mr-2">mdi-account</v-icon>
                  用户首页
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="#" @click="logout()">
                <v-list-item-title class="d-flex align-center">
                  <v-icon class="mr-2">mdi-logout</v-icon>
                  登出
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-menu>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      fixed
      width="300"
      v-if="role"
    >
      <v-app-bar elevation="1" slot="prepend" class="v-bar--underline">
        <v-img
          @click.stop="show_sheet = true"
          alt="Vuetify Name"
          class="shrink mt-1"
          contain
          min-width="100"
          :src="require('@/assets/iam-logo2.png')"
          width="100"
        />
      </v-app-bar>

      <v-list
        dense
        nav
        style="border-right: 1px solid #0000001f;"
        class="fill-height"
      >
        <v-list-item to="/dashboard">
          <v-list-item-icon><v-icon>mdi-gauge</v-icon></v-list-item-icon>
          <v-list-item-content>
            服务概览
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              {{ $t(`menu.${item.title}`) }}
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
          >
            <v-list-item-content>
              {{ $t(`menu.${child.title}`) }}
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
    <Snackbar />
  </v-app>
</template>

<script>
import { api_request } from "@/util/network";
import Snackbar from "@/components/Snackbar";
export default {
  name: "DashboardLayout",
  components: { Snackbar },
  inject: ["reload"],
  data() {
    return {
      drawer: true,
      login_name: null,
      fullname: null,
      role: null,
      items: [
        {
          action: "mdi-certificate",
          active: true,
          items: [
            {
              title: "Identity Source",
              link: "/source/list"
            },
            {
              title: "Authentication Method",
              link: "/method/list"
            },
            {
              title: "Custom Login",
              link: "/customLogin/list"
            }
            // {
            //   title: "Multi-Factor Login",
            //   link: "/mfa/list"
            // }
          ],
          title: "Authentication"
        },
        {
          action: "mdi-account-multiple-outline",
          active: true,
          items: [
            {
              title: "Departments",
              link: "/directory/tree"
            },
            {
              title: "Users",
              link: "/accounts"
            },
            {
              title: "Groups",
              link: "/groups"
            },
            {
              title: "Attribute",
              link: "/attributes"
            }
          ],
          title: "User Directory"
        },
        {
          action: "mdi-cube-scan",
          active: true,
          items: [
            {
              title: "Application List",
              link: "/applications/list"
            }
          ],
          title: "Application"
        },
        {
          action: "mdi-text-box-search-outline",
          active: true,
          items: [
            {
              title: "Login Log",
              link: "/audit/login"
            },
            {
              title: "Application Log",
              link: "/audit/applicationlogin"
            }
          ],
          title: "Audit"
        },
        // {
        //   action: "mdi-stamper",
        //   active: true,
        //   items: [
        //     {
        //       title: "Authorization",
        //       link: "/authorization"
        //     }
        //   ],
        //   title: "Authorization"
        // },
        {
          action: "mdi-cog",
          active: true,
          items: [
            {
              title: "Personalization Settings",
              link: "/setting/personalization"
            },
            {
              title: "Security Settings",
              link: "/setting/security"
            },
            {
              title: "License",
              link: "/setting/license"
            }
          ],
          title: "Setting"
        }
      ]
    };
  },
  created() {
    let identityInfo = this.$store.state.identity;
    if (identityInfo) {
      this.login_name = identityInfo.claims.login_name;
      this.fullname = identityInfo.claims.fullname;
      this.role = identityInfo.claims.role;
    }
  },
  methods: {
    logout() {
      this.$http
        .get(`api/logout`)
        .delegateTo(api_request)
        .then(() => {
          this.$snackbar.showMessage({
            content: `登出成功`,
            isError: false
          });
          setTimeout(async () => {
            let cellMode = localStorage.getItem("cellMode");
            let iconStatus = localStorage.getItem("iconStatus");
            let last_account = localStorage.getItem("last-account");
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem("cellMode", cellMode);
            localStorage.setItem("iconStatus", iconStatus);
            localStorage.setItem("last-account", last_account);
            await this.$store.commit("logout");
            this.$router.push("/login");
          }, 120);
        })
        .catch(({ code, message }) => {
          throw `登出失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  }
};
</script>

<style lang="less" scoped>
#app {
  font-family: Roboto, "微软雅黑", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #fff !important;
}
</style>
