<template>
  <v-snackbar
    right
    top
    light
    elevation="2"
    style="border-color: gray !important;"
    transition="slide-x-reverse-transition"
    v-model="show"
    @click="show = false"
  >
    <v-icon color="green" v-if="!isError" class="mr-1"
      >mdi-check-circle-outline</v-icon
    >
    <v-icon color="red" v-if="isError" class="mr-1"
      >mdi-close-circle-outline</v-icon
    >
    {{ $t(message) }}
  </v-snackbar>
</template>

<script>
// copied from: https://dev.to/stephannv/how-to-create-a-global-snackbar-using-nuxt-vuetify-and-vuex-1bda
export default {
  name: "Snackbar",
  data() {
    return {
      show: false,
      message: "",
      isError: false,
      timer: null
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/showMessage") {
        this.message = state.snackbar.content;
        this.isError = state.snackbar.isError;
        this.show = true;
        this.setupTimeout();
      }
    });
  },
  methods: {
    setupTimeout() {
      if (this.timer !== null) {
        window.clearTimeout(this.timer);
      }

      this.timer = window.setTimeout(() => {
        this.show = false;
      }, 2000);
    }
  }
};
</script>
