<template>
  <div class="home">
    <div class="pa-4">
      <v-card elevation="0">
        <v-list-item class="pa-0">
          <v-list-item-content class="pa-0">
            <v-list-item-title class="text-h5 mb-1">
              服务概览
            </v-list-item-title>
            <v-list-item-subtitle>整体运行状态</v-list-item-subtitle>
          </v-list-item-content>
          <div class="py-0 d-flex align-center" style="padding-right:224px">
            <span class="pr-4 text-h6">时间范围</span>
            <v-select
              :items="dateRanges"
              v-model="dateRange"
              item-text="name"
              item-value="time"
              style="flex: none; background: none"
              @change="dateRangechange(dateRange)"
            ></v-select>
          </div>
        </v-list-item>
      </v-card>

      <!-- 总结 -->
      <v-card elevation="0">
        <v-list-item>
          <Summary :summaryData="summaryData"></Summary>
        </v-list-item>
      </v-card>

      <v-divider class="mt-6 mb-4"></v-divider>

      <!-- 饼图 -->
      <v-card elevation="0">
        <v-list-item class="text-left pa-0">
          <v-list-item-content class="pa-0">
            <v-list-item-title class="text-h5 mb-1">
              登录详情
            </v-list-item-title>
            <v-list-item-subtitle>多维度登录数据分析</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <PieEchart :breakdownData="breakdownData"></PieEchart>
        </v-list-item>
      </v-card>
    </div>
  </div>
</template>

<script>
import Summary from "./component/Summary";
import PieEchart from "./component/PieEchart";
import { api_request } from "@/util/network";
import { dateRanges, time_val } from "./util/dateRange";

export default {
  name: "DashBoard",
  data() {
    return {
      dateRange: 24,
      start_time: "",
      end_time: "",
      dateRanges,
      summaryData: {},
      breakdownData: []
    };
  },
  created() {
    this.dateRangechange(this.dateRange);
  },
  methods: {
    dateRangechange(dateRange) {
      let { start_time, end_time } = time_val(dateRange);
      this.dashboardData(start_time, end_time);
    },
    dashboardData(start_time = null, end_time = null) {
      return this.$http
        .get(
          `api/dashboard/statistic?start_time=${start_time}&end_time=${end_time}`
        )
        .delegateTo(api_request)
        .then(res => {
          let { summary, breakdown } = res;
          this.summaryData = summary;
          this.breakdownData = breakdown;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  components: {
    Summary,
    PieEchart
  }
};
</script>

<style lang="less" scoped>
.home {
  padding-bottom: 30px !important;
}
</style>
