//封装解析登录query string值
function parseQuery(queryString) {
  var query = {};
  var pairs = queryString.split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[pair[0]] = pair[1] || "";
  }
  return query;
}

// 去掉目录拐
function dealCatalogue(s) {
  var pattern = new RegExp(
    "[`~!@#$^&%*()=|{}':;',\\[\\].<>/?~！@#￥……*└└─（）&;—|{}【】‘；：”“'。，、？]"
  );
  var rs = "";
  for (var i = 0; i < s.length; i++) {
    rs = rs + s.substr(i, 1).replace(pattern, "");
  }
  return rs;
}

// 判断本地用户属性名是否重复
function checkRepeatName(arr) {
  const obj = {};
  let flag = arr.every(item => {
    return obj[item.attribute] ? false : (obj[item.attribute] = true);
  });
  return flag;
}

// 过滤已存在的本员工属性
function checkRepeatAttribute(arr) {
  let newArr = [];
  const obj = {};
  let hasExistDatas = [];
  arr.forEach(item => {
    newArr.unshift(item);
  });

  newArr.forEach(item => {
    if (obj[item.attribute] === item.attribute) {
      item.exist = true;
      hasExistDatas.push(item);
    } else {
      item.exist = false;
      obj[item.attribute] = item.attribute;
      hasExistDatas.push(item);
    }
  });

  return hasExistDatas.reverse();
}

// transformUint8ArrayToBase64
function transformUint8ArrayToBase64(array) {
  var binary = "";
  for (var len = array.byteLength, i = 0; i < len; i++) {
    binary += String.fromCharCode(array[i]);
  }
  return window.btoa(binary).replace(/=/g, "");
}

function timestampToTime(timestamp) {
  if (timestamp === 0 || timestamp == null) {
    return "";
  } else {
    var date = new Date(timestamp * 1000);
    var Y = date.getFullYear() + "-";
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D =
      date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";

    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D;
  }
}

// 检测是否是移动端
function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  if (flag) {
    return true;
  } else {
    return false;
  }
}

// 检测当前环境是否是safari
function isSafari(ua) {
  if (
    ua.indexOf("applewebkit") > -1 &&
    ua.indexOf("mobile") > -1 &&
    ua.indexOf("safari") > -1 &&
    ua.indexOf("linux") === -1 &&
    ua.indexOf("android") === -1 &&
    ua.indexOf("chrome") === -1 &&
    ua.indexOf("ios") === -1 &&
    ua.indexOf("browser") === -1
  ) {
    return true;
  } else {
    return false;
  }
}

// 获取当前时间
function getCurTime() {
  let curTime = new Date();
  let curYear = curTime.getFullYear(); //年
  let curMonth =
    curTime.getMonth() + 1 < 10
      ? `0${curTime.getMonth() + 1}`
      : `${curTime.getMonth() + 1}`; //月
  let curDate =
    curTime.getDate() < 10 ? `0${curTime.getDate()}` : `${curTime.getDate()}`; //日
  let curHours =
    curTime.getHours() < 10
      ? `0${curTime.getHours()}`
      : `${curTime.getHours()}`; //时
  let curMinutes =
    curTime.getMinutes() < 10
      ? `0${curTime.getMinutes()}`
      : `${curTime.getMinutes()}`; //分
  let curSeconds =
    curTime.getSeconds() < 10
      ? `0${curTime.getSeconds()}`
      : `${curTime.getSeconds()}`; //秒

  return `${curYear}-${curMonth}-${curDate} ${curHours}:${curMinutes}:${curSeconds}`;
}

// 深拷贝
function deepClone(obj) {
  //判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
  var objClone = Array.isArray(obj) ? [] : {};
  //进行深拷贝的不能为空，并且是对象或者是
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === "object") {
        objClone[key] = deepClone(obj[key]);
      } else {
        objClone[key] = obj[key];
      }
    }
  }
  return objClone;
}

function randomSessionId() {
  let ua = new Uint8Array(20);
  new DataView(ua.buffer).setUint32(0, Math.floor(+new Date() / 1000));
  let crypto = window.crypto || window.msCrypto;
  if (crypto) {
    crypto.getRandomValues(ua.subarray(4, 20));
  }

  return (
    "1." +
    transformUint8ArrayToBase64(ua)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
  );
}

//16进制转换为RGB颜色值
function HexToRgb(sColor) {
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      var sColorNew = "#";
      for (var i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    //处理六位的颜色值
    let sColorChange = [];
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
    }
    return sColorChange.join(", ");
  } else {
    return sColor;
  }
}

// 是否存在其他浏览器环境
function externalBrowser() {
  let ua = window.navigator.userAgent.toLowerCase();
  if (
    ua.match(/MicroMessenger/i) == "micromessenger" ||
    ua.match(/wxwork/i) == "wxwork" ||
    ua.match(/dingtalk/i) == "dingtalk" ||
    ua.match(/lark/i) == "lark"
  ) {
    return true;
  }
}

// 是否存在source_id
function isSourceId() {
  let curUrl = location.search.substring(1);
  let source_id = parseQuery(curUrl)["source_id"];
  return source_id;
}

// 是否存在isCode
function isCode() {
  let curUrl = location.search.substring(1);
  let CODE = parseQuery(curUrl)["code"];
  return CODE;
}
// 检测是否是移动端
function isLark() {
  let flag = navigator.userAgent.match(
    /(Lark)/i
  );
  if (flag) {
    return true;
  } else {
    return false;
  }
}

export {
  isMobile,
  isLark,
  isSafari,
  parseQuery,
  getCurTime,
  dealCatalogue,
  deepClone,
  checkRepeatName,
  timestampToTime,
  transformUint8ArrayToBase64,
  randomSessionId,
  HexToRgb,
  externalBrowser,
  isSourceId,
  isCode,
  checkRepeatAttribute
};
