<template>
  <div class="summary-wrapper my-8">
    <v-row style="border-bottom:none!important">
      <v-col class="li-block" cols="2">
        <span>用户</span>
        <div class="li-style" style="border-left:8px solid #3abff3">
          {{ summary.users || 0 }}
        </div>
      </v-col>
      <v-col class="li-block" cols="2">
        <span>用户组</span>
        <div class="li-style" style="border-left:8px solid #816AFB">
          {{ summary.groups || 0 }}
        </div>
      </v-col>
      <v-col class="li-block" cols="2">
        <span>组织和部门</span>
        <div class="li-style" style="border-left:8px solid #30F0C5">
          {{ summary.departments || 0 }}
        </div>
      </v-col>
      <v-col class="li-block" cols="2">
        <span>应用数</span>
        <div class="li-style" style="border-left:8px solid #ED9274">
          {{ summary.applications || 0 }}
        </div>
      </v-col>
      <v-col class="li-block" cols="2">
        <span>已配置的认证方式</span>
        <div class="li-style" style="border-left:8px solid #0574ED">
          {{ summary.sources || 0 }}
        </div>
      </v-col>
    </v-row>

    <v-row style="border-bottom:none!important" class="mt-8">
      <v-col cols="2" class="li-block">
        <span>登录次数</span>
        <div class="li-style" style="border-left: 8px solid #5470C6">
          {{ login_sum }}
        </div>
      </v-col>
      <v-col cols="2" class="li-block">
        <span>登录成功</span>
        <div class="li-style" style="border-left: 8px solid #91CC75">
          {{ login_success }}
        </div>
      </v-col>
      <v-col cols="2" class="li-block">
        <span>登录失败</span>
        <div class="li-style" style="border-left: 8px solid #FAC858">
          {{ login_fail }}
        </div>
      </v-col>
      <v-col cols="2" class="li-block">
        <span>访问应用人次</span>
        <div class="li-style" style="border-left: 8px solid #3BA272">
          {{ accessapplications_sum }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Summary",
  props: ["summaryData"],
  data() {
    return {
      summary: {
        users: 0,
        groups: 0,
        departments: 0,
        applications: 0,
        sources: 0,
        authentications: {
          ok: 0,
          fail: 0
        },
        access_applications: {
          ok: 0,
          fail: 0
        }
      },
      login_success: 0,
      login_fail: 0
    };
  },
  created() {
    this.get_summary();
  },
  methods: {
    get_summary() {
      if (this.summaryData && this.summaryData.authentications) {
        this.summary = this.summaryData;
        this.login_success = this.summaryData.authentications.ok || 0;
        this.login_fail = this.summaryData.authentications.fail || 0;
      }
    }
  },
  watch: {
    summaryData: {
      handler() {
        this.get_summary();
      },
      deep: true
    }
  },
  computed: {
    login_sum() {
      const { ok = 0, fail = 0 } = this.summary.authentications;

      return ok + fail;
    },
    accessapplications_sum() {
      const { ok = 0, fail = 0 } = this.summary.access_applications;

      return ok + fail;
    }
  }
};
</script>

<style lang="less" scoped>
.summary-wrapper {
  width: 100%;
}

.li-block {
  border: 1px solid rgb(165, 158, 158);
  margin-right: 10px;
}

.li-style {
  font-weight: 800;
  font-size: 36px;
  padding-left: 10px;
}
</style>
