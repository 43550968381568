<template>
  <v-navigation-drawer
    app
    temporary
    width="650px"
    right
    v-model="show[0]"
    class="directory-tree"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            entity_info.type === "account"
              ? `${entity_info.name}转岗`
              : `移动单位或部门（${entity_info.name}）`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-container class="pa-4" style="min-height: 75vh">
      <v-row style="border-bottom: none !important;">
        <v-col cols="12">
          <v-alert dense outlined type="info">
            请选择需要移动到的单位或部门, 并点击'确认移动'按钮。
          </v-alert>
          <v-treeview
            :load-children="fetchChildren"
            :active.sync="activeItems"
            :items="items"
            activatable
            selected-color="indigo"
            return-object
            class="mt-3"
            style="height: 75vh;"
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="item.root">
                mdi-home
              </v-icon>
              <v-icon v-else>
                mdi-domain
              </v-icon>
            </template>
          </v-treeview>
        </v-col>
      </v-row>
    </v-container>

    <v-row>
      <v-col class="d-flex" cols="3"></v-col>
      <v-col cols="9" class="text-left">
        <v-btn elevation="4" medium color="primary" @click="doTransfer()"
          >确认移动</v-btn
        >
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<style lang="sass">
.directory-tree
  .v-treeview-node__root,
  .v-treeview-node__root > .v-treeview-node__content > *
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
</style>

<script>
import { api_request } from "@/util/network";

export default {
  name: "TransferDrawer",
  components: {},
  data() {
    return {
      items: [
        {
          id: 0,
          name: "Loading...",
          children: []
        }
      ],
      activeItems: [],
      activeItem: null
    };
  },
  props: ["show", "title", "entity_info"],
  methods: {
    loadRootDepartment() {
      return this.$http
        .get(`api/department/root`)
        .delegateTo(api_request)
        .then(root => {
          this.$set(this.activeItems, 0, {
            id: root.id,
            name: "企业根目录"
          });
          this.$set(this.items, 0, {
            id: root.id,
            root: true,
            name: "企业根目录",
            children: []
          });
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchChildren(item) {
      return this.$http
        .get(`api/department/${item.id}/children`)
        .delegateTo(api_request)
        .then(children => {
          item.children.push(...children);
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    doTransfer() {
      let payload = {
        department_id: this.activeItem.id
      };

      if (this.entity_info.type === "account") {
        this.$http
          .post(`api/account/${this.entity_info.id}/transfer`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            return `已成功移动账户 ${this.entity_info.name} 至 ${this.activeItem.name}`;
          })
          .catch(({ code, message }) => {
            throw `账号移动失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      } else if (this.entity_info.type === "group") {
        this.$http
          .post(`api/group/${this.entity_info.id}/transfer`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            return `已成功移动用户组 ${this.entity_info.name} 至 ${this.activeItem.name}`;
          })
          .catch(({ code, message }) => {
            throw `用户组移动失败：${this.$t(
              "api." + code
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      } else {
        this.$http
          .post(`api/department/${this.entity_info.id}/transfer`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            return `已成功移动部门 ${this.entity_info.name} 至 ${this.activeItem.name}`;
          })
          .catch(({ code, message }) => {
            throw `部门移动失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    }
  },
  watch: {
    show(val) {
      if (!val[0]) {
        this.items = [
          {
            id: 0,
            name: "Loading...",
            children: []
          }
        ];
        this.activeItem = null;
        this.activeItems = [{ id: 0 }];
      } else {
        return this.loadRootDepartment();
      }
    },
    activeItems(val) {
      if (val.length) {
        this.activeItem = val[0];
      } else {
        // forbid deactivate behavior
        this.$set(this.activeItems, 0, this.activeItem);
      }
    }
  }
};
</script>
