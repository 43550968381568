<template>
  <v-navigation-drawer
    app
    temporary
    width="850px"
    right
    v-model="show[0]"
    class="directory-tree"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          <v-btn icon small @click.stop="tab = 0" v-if="tab"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          {{
            tab
              ? `添加用户至用户组 ${entity_info.name}(#${entity_info.id})`
              : `用户组 ${entity_info.name}(#${entity_info.id}) 成员用户`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-container class="pa-4">
      <v-row style="border-bottom: none !important;">
        <v-col cols="12">
          <v-tabs-items v-model="tab">
            <v-tab-item key="existing">
              <v-app-bar
                elevation="0"
                dense
                flat
                class="directory-toolbar"
                style="background: none;"
                height="56"
              >
                <v-text-field
                  dense
                  hide-details
                  placeholder="搜索用户名"
                  class="ml-2 pr-3"
                  style="width: 250px; flex: none"
                  v-model="filterValue"
                  @input="searchAccounts(filterValue)"
                  @keydown.enter="searchAccounts(filterValue)"
                  autocomplete="filterValue"
                >
                  <v-icon slot="append" color="gray">
                    mdi-magnify
                  </v-icon>
                </v-text-field>
                <v-btn
                  depressed
                  color="primary"
                  @click.stop="searchAccounts(filterValue)"
                >
                  {{ $t("group.Search Existing Account") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click.stop="tab = 1">
                  {{ $t("group.Add Account") }}
                </v-btn>
              </v-app-bar>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left pl-4">
                      外部ID
                    </th>
                    <th class="text-left">
                      {{ $t("account.login_name") }}
                    </th>
                    <th class="text-left">
                      {{ $t("account.nickname") }}
                    </th>
                    <th class="text-left">
                      {{ $t("account.path") }}
                    </th>
                    <th class="text-center">
                      {{ $t("Actions") }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="accounts.length > 0">
                  <tr v-for="item in accounts" :key="item.id" class="text-left">
                    <td class="pl-4">{{ item.external_id }}</td>
                    <td>{{ item.login_name || "-" }}</td>
                    <td>{{ item.fullname || item.nickname || "-" }}</td>
                    <td>{{ item.path || "-" }}</td>
                    <td class="text-center">
                      <v-btn
                        icon
                        elevation="0"
                        color="red"
                        @click.stop="removeGroup(item)"
                        :disabled="item.loading || item.removed"
                      >
                        <v-icon v-if="!item.loading">
                          mdi-trash-can-outline
                        </v-icon>
                        <v-progress-circular
                          v-else
                          indeterminate
                          color="red"
                          size="24"
                          width="3"
                        ></v-progress-circular>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="text-center">
                    <td
                      colspan="5"
                      style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                    >
                      暂无数据
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-tab-item>
            <v-tab-item key="available">
              <v-app-bar
                elevation="0"
                dense
                flat
                class="directory-toolbar"
                style="background: none;"
                height="56"
              >
                <v-text-field
                  dense
                  hide-details
                  placeholder="搜索用户名"
                  class="ml-2 pr-3"
                  style="width: 250px; flex: none"
                  v-model="filterValue"
                  @input="searchAccounts(filterValue)"
                  @keydown.enter="searchAccounts(filterValue)"
                  autocomplete="filterValue"
                >
                  <v-icon slot="append" color="gray">
                    mdi-magnify
                  </v-icon>
                </v-text-field>
                <v-btn
                  depressed
                  color="primary"
                  @click.stop="searchAccounts(filterValue)"
                >
                  {{ $t("group.Search Available Account") }}
                </v-btn>
                <!--                <v-spacer></v-spacer>-->
                <!--                <v-btn depressed color="primary" @click.stop="addGroups()">-->
                <!--                  {{ $t("account.Do Add Groups") }}-->
                <!--                </v-btn>-->
              </v-app-bar>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left pl-4">
                        外部ID
                      </th>
                      <th class="text-left">
                        {{ $t("account.login_name") }}
                      </th>
                      <th class="text-left">
                        {{ $t("account.nickname") }}
                      </th>
                      <th class="text-left">
                        {{ $t("account.path") }}
                      </th>
                      <th class="text-center">
                        {{ $t("Actions") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="accounts.length > 0">
                    <tr
                      v-for="item in accounts"
                      :key="item.id"
                      class="text-left"
                    >
                      <td class="pl-4">{{ item.external_id }}</td>
                      <td>{{ item.login_name || "-" }}</td>
                      <td>{{ item.fullname || item.nickname || "-" }}</td>
                      <td>{{ item.path || "-" }}</td>
                      <td class="text-center">
                        <span v-if="item.added" class="grey--text text-body-2"
                          >已添加</span
                        >
                        <v-btn
                          v-else
                          elevation="0"
                          color="primary"
                          @click.stop="addAccount(item)"
                        >
                          <span v-if="!item.loading">
                            添加
                          </span>
                          <v-progress-circular
                            v-else
                            indeterminate
                            color="primary"
                            size="24"
                            width="3"
                          ></v-progress-circular>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="text-center">
                      <td
                        colspan="5"
                        style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                      >
                        暂无数据
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <ConfirmBox ref="confirm" />
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import ConfirmBox from "@/components/ConfirmBox";

export default {
  name: "GroupsAccountDrawer",
  components: { ConfirmBox },
  data() {
    return {
      accounts: [],
      selectedGroups: [],

      // UI
      filterValue: "",
      tab: 0,
      dragStarted: false
    };
  },
  props: ["show", "title", "entity_info"],
  methods: {
    loadAccounts() {
      return this.$http
        .get(`api/group/${this.entity_info.id}/accounts`)
        .delegateTo(api_request)
        .then(accounts => {
          this.accounts = accounts;
          this.filterValue = "";
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    loadAvailableAccounts() {
      return this.$http
        .get(`api/group/${this.entity_info.id}/accounts/available`)
        .delegateTo(api_request)
        .then(accounts => {
          this.accounts = accounts;
          this.filterValue = "";
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    addAccounts() {
      return this.$http
        .post(`api/group/${this.entity_info.id}/accounts/add`, {
          account_ids: this.selectedGroups
        })
        .delegateTo(api_request)
        .then(() => {
          this.refreshList();
        })
        .delegateTo(this.$snackbar.delegate);
    },
    searchAccounts(filterValue) {
      if (this.tab === 0) {
        return this.$http
          .get(
            `api/group/${this.entity_info.id}/accounts/search/existing?value=${filterValue}`
          )
          .delegateTo(api_request)
          .then(accounts => {
            this.accounts = accounts;
          })
          .delegateTo(this.$snackbar.delegateError);
      } else {
        return this.$http
          .get(
            `api/group/${this.entity_info.id}/accounts/search/available?value=${filterValue}`
          )
          .delegateTo(api_request)
          .then(accounts => {
            this.accounts = accounts;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    addAccount(account) {
      this.$set(account, "loading", true);
      return this.$http
        .post(`api/group/${this.entity_info.id}/accounts/add`, {
          account_ids: [account.id]
        })
        .delegateTo(api_request)
        .then(() => {
          this.$set(account, "added", true);
          return `${account.nickname || account.login_name}(#${
            account.id
          }) 已添加至用户组`;
        })
        .catch(({ code, message }) => {
          this.$set(account, "added", false);
          throw `添加用户组失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegate)
        .finally(() => {
          account.loading = false;
        });
    },
    removeGroup(account) {
      this.$set(account, "loading", true);

      let _this = this;
      this.$refs.confirm
        .showConfirm({
          contentText: `确认要将 ${account.nickname || account.login_name}(#${
            account.id
          }) 移出用户组？  `,
          callback: function() {
            return this.$http
              .post(`api/group/${_this.entity_info.id}/accounts/remove`, {
                account_ids: [account.id]
              })
              .delegateTo(api_request)
              .then(() => {
                this.$set(account, "removed", true);
                return `已成功移出用户组 ${account.nickname ||
                  account.login_name}(#${account.id})`;
              })
              .catch(({ code, message }) => {
                throw `移出用户组失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          account.loading = false;
        })
        .finally(() => {
          account.loading = false;
        });
    },
    refreshList(tab) {
      tab = tab ? tab : this.tab;

      if (tab === 1) {
        return this.loadAvailableAccounts();
      } else {
        return this.loadAccounts();
      }
    }
  },
  watch: {
    watchShow(val) {
      if (val) {
        this.loadAccounts();
      } else {
        this.tab = 0;
        this.accounts = [];
      }
    },
    tab(val) {
      this.refreshList(val);
    },
    activeItems(val) {
      if (val.length) {
        this.activeItem = val[0];
      } else {
        // forbid deactivate behavior
        this.$set(this.activeItems, 0, this.activeItem);
      }
    }
  },
  computed: {
    watchShow() {
      return this.show[0];
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}

/deep/ .v-data-table__wrapper {
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
</style>
